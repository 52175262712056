import methods from '@/enums/methods';

const { default: Iris } = require('../base/iris');

class AdminOnlineRegistrationService extends Iris {
    getAllUsers() {
        return this.fetch({ url: '/admin/user' });
    }

    importContract({
        file, userId, contractName, description,
    }) {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('user_id', userId);
        formData.append('name', contractName);
        formData.append('description', description);

        return this.fetch({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: methods.POST,
            url: '/contract',
        });
    }

    userDocumentInReviewStatus() {
        return this.fetch({
            url: '/user/document/review',
        });
    }

    downloadUserDocument(userDocumentId) {
        return this.fetch({
            url: `/user/document/file/${userDocumentId}`,
            responseType: 'blob',
        });
    }

    giveOpinion({ id, status, opinion }) {
        return this.fetch({
            url: `/user/document/${id}`,
            method: methods.PUT,
            data: {
                status, opinion,
            },
        });
    }
}

export default new AdminOnlineRegistrationService();
