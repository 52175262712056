import axios from 'axios';

const BASE_URL = process.env.VUE_APP_IRIS_URL;

const token = sessionStorage.getItem('token');

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${token}`,
    },
    timeout: 0,
});
